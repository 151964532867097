export default {
    auth: {
        title: 'Сервис для\u00A0управления посуточной недвижимостью',
        sign_in: {
            title: 'Авторизация',
            login_title: 'Почта или телефон',
            password_title: 'Пароль',
            button_title: 'Войти',
            forget_password: 'Забыли пароль?',
            restore_password: 'Восстановить пароль',
            no_account: 'Нет аккаунта?',
            register: 'Зарегистрироваться',
        },
        restore_password: {
            title: 'Восстановление пароля',
            username_title: 'Телефон или email',
            next: 'Далее',
            go_back: 'Вернуться назад',
            change_email: 'Изменить почту',
            change_phone: 'Изменить номер телефона',
            email_sent: 'Вам на почту отправлен код. Если вы не можете найти письмо - проверьте папку “Спам”.',
            sms_sent: 'Вам на номер телефона отправлен код.',
            code: 'Код активации',
            get_new_code: 'Получить новый код',
            password_title: 'Придумайте пароль (минимум 8 символов)',
            finish: 'Завершить',
        },
        sign_up: {
            title_employee: 'Регистрация сотрудника',
            title_renter: 'Регистрация арендодателя',
            name: 'Имя',
            phone_with_code: 'Телефон (придёт код подтверждения)',
            phone: 'Телефон',
            email_with_code: 'Почта (придёт код подтверждения)',
            is_landlord: 'Я сдаю жилье',
            is_renter: 'Я хочу снять жильё',
            terms_agreed: 'Я прочел и согласен с {Пользовательским соглашением|{user_agreed}} {br} и {Согласием на обработку персональных данных|{privacy_agreed}}',
            ads_agreed: 'Я даю {Согласие на получение рекламной рассылки|{ads_agreed}}',
            next: 'Далее',
            alert_modal: 'RealtyCalendar - это сервис для арендодателей. {br} К сожалению, вы не сможете арендовать жильё с нашей помощью. Для поиска жилья вы можете воспользоваться агрегаторами посуточной аренды: Авито, Суточно, Островок, Яндекс Путешествия и\u00A0т.д.',
            close: 'Закрыть',
            has_account: 'Уже зарегистрированы?',
            sign_in: 'Войти',
            sms_code: 'Введите код из СМС',
            email_code: 'Введите код из письма',
            sms_confirm: 'Вам на номер {phone} отправлен код',
            email_confirm: 'Вам на адрес {email} отправлен код. Если не видите письмо, проверьте папку Спам.',
            get_code: 'Получить новый код',
            password: 'Придумайте пароль (минимум 8 символов)',
            email: 'Укажите вашу почту',
            your_email: 'Ваша почта',
            back: 'Назад',
            how_many_objects: 'Сколько объектов у вас в управлении?',
            promocode: 'Промокод',
            i_have_promocode: 'У меня есть промокод',
            just_start: '0 (только начинаю)',
            one: '1',
            under_five: '2 - 4',
            under_ten: '5 - 9',
            under_twenty: '10 - 20',
            more_than_twenty: 'больше 20',
            finish: 'Завершить регистрацию',
            error_phone: 'Номер телефона имеет неверный формат',
        },
        confirm_email: {
            title: 'Подтверждение почты',
            text: 'Спасибо! Почта подтверждена.',
            go_to_lk: 'Перейти в личный кабинет'
        },
    }
}
